import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import TrustpilotReviews from "@pittica/gatsby-plugin-trustpilot-widget"

const Navbar = () => {
  return (
    <Container>
      <ResponsiveContainer>
        <FooterColumn>
          <Logo src="/black_logo.png" alt="Logo" />
          <Text style={{ marginLeft: 10 }}>
            Copyright © {new Date().getFullYear()}
          </Text>
        </FooterColumn>
        <FooterColumn>
        <a href="https://uk.trustpilot.com/review/steelskins.co" target="_blank" rel="noopener noreferrer">
        <TrustpilotLogo src="/images/trustpilot_logo.svg" alt="TrustpilotLogo"/>
        </a>
        </FooterColumn>
        <AddressRow>
          <ContactUs>
            <Title>Here to help</Title>
            <StyledLink to="/process">The Process</StyledLink>
            <StyledLink to="/faq">FAQ</StyledLink>
            <StyledLink to="/contact">Contact Us</StyledLink>
            <StyledLink to="/refund-policy">Refund Policy</StyledLink>
            <StyledLink to="/terms-and-conditions">Terms and Conditions</StyledLink>
          </ContactUs>
          <Address>
            Unit 3 Watling Gate
            <br />
            297-303 Edgware Road
            <br />
            London
            <br />
            NW9 6NB
            <br />
            United Kingdom
            <br />
            support@steelskins.co
          </Address>
          {/* <TrustpilotContainer>
            <TrustpilotReviews
              language="en"
              culture="GB"
              theme="dark"
              width="100%"
              height="28px"
              username="steelskins.co"
              template="5419b6a8b0d04a076446a9ad"
              business="60aebe0f3f57bf002929227a"
            />
          </TrustpilotContainer> */}
        </AddressRow>
      </ResponsiveContainer>
    </Container>
  );
};

const TrustpilotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 16px;
  margin-right: 20%;
  height: 110px;
  transform: scale(1.2);
  @media only screen and (max-width: 1400px) {
    margin-right: unset;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.white};
  padding: 60px 0%;
  z-index: 2;
  color: ${props => props.theme.black};
`

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 1140px;
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
    justify-content: center;
  }
`

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${props => props.theme.black};
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 14px;
  }

  @media only screen and (min-width: 1024px) {
    margin-right: 250px;
  }
`

const AddressRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  color: ${props => props.theme.black};
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Logo = styled.img`
  height: 50px;
`

const TrustpilotLogo = styled.img`
  height: 40px;
`

const ContactUs = styled.span`
  white-space: pre;
  display: flex;
  flex-direction: column;
  font: ${props => props.theme.light};
  color: ${props => props.theme.black};
  @media only screen and (max-width: 1400px) {
    margin-bottom: 24px;
    margin-left: 0px;
    width: 75%;
  }
  text-transform: uppercase;
`

const Text = styled.span`
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`

const Title = styled.span`
  font: ${props => props.theme.bold};
  color: ${props => props.theme.black};
  font-weight: 500;
  letter-spacing: 0.6px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  font: ${props => props.theme.light};
  color: ${props => props.theme.black};
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
  transition: opacity ease-in-out 0.3s;
  font-weight: 400;
  letter-spacing: 0.2px;
`

const Address = styled.div`
  white-space: pre;
  display: flex;
  flex-direction: column;
  font: ${props => props.theme.light};
  color: ${props => props.theme.black};
  margin-left: 24px;
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
    margin-bottom: 24px;
    margin-left: 0px;
    width: 75%;
  }
  font-weight: 400;
  letter-spacing: 0.1px;
`

export default Navbar
